'use client';

import { ErrorBoundary } from 'components';
import { incrementTelemetry } from 'core-events';
import React from 'react';
import { UserData } from 'stockblocks-client/src/types';

import {
    MainNav,
    VideoOptions,
    AudioOptions,
    useMedia,
    MainNavProps,
    TemplateOptions,
} from '@videoblocks/shared-components';

import { imagesUrl } from '../lib/constants';

const MainNavContainer = (props: UserData) => {
    const {
        auth: { user, subscription, permissions, featureFlags, isLoggedIn },
        ui: {
            nav: {
                menu: {
                    collections,
                    top_level_categories,
                    top_level_categories: {
                        business,
                        'creative-tools': creativeToolCategories,
                        resources: resourcesCategories,
                    },
                },
            },
        },
    } = props;

    const mainNavProps: MainNavProps = {
        user,
        subscription,
        permissions,
        businessCategories: business,
        top_level_categories,
        creativeToolCategories: creativeToolCategories.categories,
        resourcesCategories,
        logger: incrementTelemetry,
        collections,
        constants: {
            videoOptions: VideoOptions,
            audioOptions: AudioOptions,
            templateOptions: TemplateOptions,
        },
        imagesUrl,
        useMedia,
        isLoggedIn,
    };

    return (
        <ErrorBoundary fallback={<p>Something went wrong</p>}>
            <MainNav {...mainNavProps} />
        </ErrorBoundary>
    );
};

export default MainNavContainer;
