'use client';

import React, { Component, ReactNode, PropsWithChildren } from 'react';

interface ErrorBoundaryProps extends PropsWithChildren {
    fallback?: ReactNode;
    onError?: (error: Error, errorInfo: React.ErrorInfo) => void;
}

interface ErrorBoundaryState {
    didCatch: boolean;
    error: Error | null;
    errorInfo: React.ErrorInfo | null;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            didCatch: false,
            error: null,
            errorInfo: null,
        };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // Catch errors in any child components and re-renders with an error message
        this.props.onError?.(error, errorInfo);
        this.setState({
            didCatch: true,
            error,
            errorInfo,
        });
    }

    render() {
        if (this.state.didCatch) {
            const {
                fallback = (
                    <div>
                        <h1>Something went wrong.</h1>
                    </div>
                ),
            } = this.props;

            return <>{fallback}</>;
        }

        // Render children if there's no error
        return this.props.children;
    }
}
